import { zodResolver } from '@hookform/resolvers/zod';
import PrimaryButton from 'DesignComponents/Atoms/Buttons/PrimaryButton';
import Checkbox from 'DesignComponents/Atoms/Input/Checkbox';
import InputReactForm from 'DesignComponents/Atoms/Input/InputReactForm';
import Heading from 'DesignSystem/Typography/Headings/Heading';
import ValidationResultModel from 'Models/Validation/ValidationResultModel.interface';
import Button from 'Shared/Components/Buttons/Button';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { styled } from 'Theme/stitches.config';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { UpdateDeliveryDetails } from '../Checkout';
import AutoSuggestAddress, { SuggestionType } from '../AutoSuggestAddress';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import CheckoutPageModel from 'Models/Pages/CheckoutPage/CheckoutPageModel.interface';
import {
  CHECKOUT_UPDATE_FORM,
  EventDispatcher,
} from 'Shared/Common/EventDispatcher';
import ShippingAndBillingAddressModel from 'Models/Checkout/CustomerDetails/ShippingAndBillingAddressModel.interface';
import OrderAddressModel from 'Models/Shared/OrderAddressModel.interface';
import { debounce } from 'Shared/Common/debounce';
import {
  GetShippingDestination,
  SetShippingDestination,
  SwitchShippingDestination,
} from 'Commerce/CountryPicker/ShippingDestination';
import { FetchCartAndNotifyAll } from 'Commerce/Cart/Cart';
import PromptPopupModel from 'Models/PromptPopupModel.interface';
import SwitchShippingDestinationResponseModel from 'Models/Shipping/SwitchShippingDestinationResponseModel.interface';
import PromptPopup from 'DesignComponents/Organisms/PromptPopup/PromptPopup';
import Label from 'DesignSystem/Typography/Label/Label';
import {
  SelectList,
  SelectListOption,
} from 'DesignComponents/Atoms/Input/SelectList';

type PropTypes = {
  goToNextStep: (step: number, open: boolean) => void;
  active: boolean;
  open: boolean;
};
export const MAX_NAME_LENGTH = 40;
export const MIN_NAME_LENGTH = 2;
export const MAX_LENGTH_CITY = 40;
export const MAX_LENGTH_ADDRESS = 60;
export const MAX_LENGTH_ZIP_CODE = 10;

const DeliveryStep = ({ goToNextStep, active }: PropTypes) => {
  const { languageRoute } = useAppSettingsData();
  const currentShippingDestination = GetShippingDestination();
  const { checkoutFormDataModel, shippingDestinations } =
    useCurrentPage<CheckoutPageModel>();
  const [billingSameAsShipping, setBillingSameAsShipping] = useState<boolean>(
    checkoutFormDataModel.shippingAndBillingAddressModel
      ?.billingIsSameAsShipping !== undefined
      ? checkoutFormDataModel.shippingAndBillingAddressModel
          .billingIsSameAsShipping
      : true
  );

  const [selectedShippingDestination, setSelectedShippingDestination] =
    useState<string>(currentShippingDestination.code);
  const [requiresCustomerConfirmation, setRequiresCustomerConfirmation] =
    useState<boolean>(false);
  const [popupModel, setPopupModel] = useState<PromptPopupModel>({
    heading: '',
    bodyText: '',
    cancelButtonText: '',
    actionButtonText: '',
  });

  const [billingAddress, setBillingAddress] = useState<SuggestionType>(
    {} as SuggestionType
  );
  const [shippingAddress, setShippingAddress] = useState<SuggestionType>(
    {} as SuggestionType
  );

  const [
    isShippingAddressSelectedFromList,
    setIsShippingAddressSelectedFromList,
  ] = useState(false);

  const [
    isBillingAddressSelectedFromList,
    setIsBillingAddressSelectedFromList,
  ] = useState(billingSameAsShipping);

  const formRef = useRef<HTMLFormElement>(null);

  const {
    checkoutLabels: {
      address,
      billingAddressSameAsDeliveryAddress,
      country,
      city,
      zipCode,
      firstName,
      lastName,
      infoLabel = '',
      billingAddress: billingAddressLabel,
      goToTermsAndConditions,
    },
    validationLabels: {
      fillInCity,
      fillInFirstName,
      fillInLastName,
      fillInAddress,
      invalidAddress,
      exceededMaxLengthFirstName,
      exceededMaxLengthLastName,
      fillInMinLengthZipCode,
      fillInZipCode,
      exceededMaxLengthZipCode,
    },
  } = useTranslationData();

  useEffect(() => {
    if (!checkoutFormDataModel) return;

    //Add saved address to state so fields are pre-populated
    if (
      checkoutFormDataModel.shippingAndBillingAddressModel?.shipping
        ?.fullAddress
    ) {
      setShippingAddress({
        street:
          checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping
            ?.street,
        street_number:
          checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping
            ?.streetNumber,
        letter:
          checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping
            ?.letter,
        door: checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping
          ?.door,
        floor:
          checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping
            ?.floor,
        locality:
          checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping?.city,
        postcode:
          checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping
            ?.postalCode,
      } as SuggestionType);
    }

    if (
      checkoutFormDataModel.shippingAndBillingAddressModel?.billing?.fullAddress
    ) {
      setBillingAddress({
        street:
          checkoutFormDataModel?.shippingAndBillingAddressModel?.billing
            ?.street,
        street_number:
          checkoutFormDataModel?.shippingAndBillingAddressModel?.billing
            ?.streetNumber,
        letter:
          checkoutFormDataModel?.shippingAndBillingAddressModel?.billing
            ?.letter,
        door: checkoutFormDataModel?.shippingAndBillingAddressModel?.billing
          ?.door,
        floor:
          checkoutFormDataModel?.shippingAndBillingAddressModel?.billing?.floor,
        locality:
          checkoutFormDataModel?.shippingAndBillingAddressModel?.billing?.city,
        postcode:
          checkoutFormDataModel?.shippingAndBillingAddressModel?.billing
            ?.postalCode,
      } as SuggestionType);
    }

    if (billingSameAsShipping)
      setValue(
        'billing_fullAddress',
        getAddressString(
          checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping
        )
      );
    else {
      setValue(
        'billing_fullAddress',
        getAddressString(
          checkoutFormDataModel?.shippingAndBillingAddressModel?.billing
        )
      );
    }
  }, [checkoutFormDataModel]);

  useEffect(() => {
    if (isDenmark) {
      if (getValues('shipping_fullAddress')) {
        setIsShippingAddressSelectedFromList(true);
      }
      if (getValues('billing_fullAddress')) {
        setIsBillingAddressSelectedFromList(true);
      }
    }
  }, []);

  const showForSpecificCountries = useMemo(() => {
    switch (selectedShippingDestination) {
      case 'DK':
      case 'SE':
        return true;

      default:
        return false;
    }
  }, [selectedShippingDestination]);

  const isDenmark = selectedShippingDestination === 'DK';
  const isSwedenOrFinland =
    selectedShippingDestination === 'SE' ||
    selectedShippingDestination === 'FI';

  const getAddressString = (address: OrderAddressModel) => {
    if (!address || !address.fullAddress) return '';
    return address.fullAddress;
  };

  const DeliveryConfig = z.object({
    shipping_firstName: z
      .string()
      .min(MIN_NAME_LENGTH, { message: fillInFirstName })
      .max(MAX_NAME_LENGTH, { message: exceededMaxLengthFirstName }),
    shipping_lastName: z
      .string()
      .min(MIN_NAME_LENGTH, { message: fillInLastName })
      .max(MAX_NAME_LENGTH, { message: exceededMaxLengthLastName }),
    shipping_postalCode: z
      .string()
      .min(4, { message: fillInZipCode })
      .max(MAX_LENGTH_ZIP_CODE, {
        message: exceededMaxLengthZipCode,
      }),
    shipping_city: z.string().min(2, { message: fillInCity }),
    shipping_fullAddress: z.string().min(2, fillInAddress),
    billing_firstName: z
      .string()
      .min(MIN_NAME_LENGTH, { message: fillInFirstName })
      .max(MAX_NAME_LENGTH, { message: exceededMaxLengthFirstName }),
    billing_lastName: z
      .string()
      .min(MIN_NAME_LENGTH, { message: fillInLastName })
      .max(MAX_NAME_LENGTH, { message: exceededMaxLengthLastName }),
    billing_postalCode: z
      .string()
      .min(4, { message: fillInZipCode })
      .max(MAX_LENGTH_ZIP_CODE, {
        message: exceededMaxLengthZipCode,
      }),
    billing_city: z.string().min(2, { message: fillInCity }),
    billing_fullAddress: z.string().min(2, fillInAddress),
  });

  type OrderKeys = keyof z.infer<typeof DeliveryConfig>;

  const MapToOrderAddress = (
    data: Record<OrderKeys, string>,
    sameAsShipping = true
  ): ShippingAndBillingAddressModel => {
    const createAddress = (prefix: string, address: SuggestionType) => {
      const { street, street_number, letter, floor, door } = address;

      const firstNameKey = `${prefix}_firstName` as OrderKeys;
      const lastNameKey = `${prefix}_lastName` as OrderKeys;
      const fullAddressKey = `${prefix}_fullAddress` as OrderKeys;
      const infoKey = `${prefix}_info` as OrderKeys;
      const cityKey = `${prefix}_city` as OrderKeys;
      const postalCodeKey = `${prefix}_postalCode` as OrderKeys;

      const firstName = data[firstNameKey];
      const lastName = data[lastNameKey];
      const info = data[infoKey];
      const fullAddress = data[fullAddressKey];
      const city = data[cityKey];
      const postalCode = data[postalCodeKey];

      if (showForSpecificCountries) {
        return {
          firstName,
          lastName,
          city,
          postalCode,
          fullAddress,
          info,
          country: currentShippingDestination.code,
          letter,
          floor,
          door,
          street,
          streetNumber: street_number,
        } as OrderAddressModel;
      }

      return {
        firstName,
        lastName,
        city,
        postalCode,
        fullAddress,
        country: currentShippingDestination.code,
      } as OrderAddressModel;
    };

    const result: ShippingAndBillingAddressModel = {
      shipping: createAddress('shipping', shippingAddress),
      billing: sameAsShipping
        ? ({} as OrderAddressModel)
        : createAddress('billing', billingAddress),
      billingIsSameAsShipping: sameAsShipping,
    };

    return result;
  };

  const {
    formState,
    control,
    register,
    setValue,
    setError,
    getValues,
    handleSubmit,
    trigger,
    clearErrors,
  } = useForm<Record<string, string>>({
    resolver: zodResolver(DeliveryConfig),
    mode: 'onSubmit',
    defaultValues: {
      shipping_firstName:
        checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping
          ?.firstName,
      shipping_lastName:
        checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping
          ?.lastName,
      shipping_country: currentShippingDestination?.name,
      shipping_fullAddress: getAddressString(
        checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping
      ),
      shipping_info:
        checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping?.info,
      shipping_postalCode:
        checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping
          ?.postalCode,
      shipping_city:
        checkoutFormDataModel?.shippingAndBillingAddressModel?.shipping?.city,
      billing_firstName:
        checkoutFormDataModel?.shippingAndBillingAddressModel?.billing
          ?.firstName,
      billing_lastName:
        checkoutFormDataModel?.shippingAndBillingAddressModel?.billing
          ?.lastName,
      billing_fullAddress: getAddressString(
        checkoutFormDataModel?.shippingAndBillingAddressModel?.billing
      ),
      billing_info:
        checkoutFormDataModel?.shippingAndBillingAddressModel?.billing?.info,
      billing_postalCode:
        checkoutFormDataModel?.shippingAndBillingAddressModel?.billing
          ?.postalCode,
      billing_city:
        checkoutFormDataModel?.shippingAndBillingAddressModel?.billing?.city,
    },
  });

  const triggerValidation = (field: string, errorMessage: string) => {
    setError(field, {
      type: 'manual',
      message: errorMessage,
    });
  };

  const submit = () => {
    if (isDenmark) {
      if (
        getValues('shipping_fullAddress') &&
        !isShippingAddressSelectedFromList
      ) {
        triggerValidation('shipping_fullAddress', invalidAddress);
        return false;
      }
      if (
        getValues('billing_fullAddress') &&
        !isBillingAddressSelectedFromList
      ) {
        triggerValidation('billing_fullAddress', invalidAddress);
        return false;
      }
    }

    if (isSwedenOrFinland) {
      const shippingPostalCodeLength =
        getValues('shipping_postalCode')?.replace(/\s+/g, '').length ?? 0;
      const billingPostalCodeLength =
        getValues('billing_postalCode')?.replace(/\s+/g, '').length ?? 0;

      if (shippingPostalCodeLength > 5) {
        triggerValidation('shipping_postalCode', exceededMaxLengthZipCode);
        return false;
      }

      if (billingPostalCodeLength > 5) {
        triggerValidation('billing_postalCode', exceededMaxLengthZipCode);
        return false;
      }

      if (shippingPostalCodeLength > 0 && shippingPostalCodeLength < 5) {
        triggerValidation('shipping_postalCode', fillInMinLengthZipCode);
        return false;
      }

      if (billingPostalCodeLength > 0 && billingPostalCodeLength < 5) {
        triggerValidation('billing_postalCode', fillInMinLengthZipCode);
        return false;
      }
    }

    UpdateDeliveryDetails(
      MapToOrderAddress(getValues(), billingSameAsShipping),
      languageRoute
    )
      .then((res) => {
        if (res && res.ok) {
          res.json().then((data: ValidationResultModel) => {
            if (!data.isValid && data.errors && Array.isArray(data.errors)) {
              data.errors.forEach((error) => {
                let key = error.propertyName.split(
                  'shippingAndBillingAddressModel.'
                )[1];
                key = key.replace('.', '_');

                setError(key, { type: 'manual', message: error.errorMessage });
              });
            } else {
              EventDispatcher.dispatch(
                CHECKOUT_UPDATE_FORM,
                'shippingAndBillingAddressModel',
                MapToOrderAddress(getValues(), billingSameAsShipping)
              );
              goToNextStep(3, true);
            }
          });
        }
      })
      .catch((error) => {
        console.error('Error when saving address', error); //eslint-disable-line
      });
  };

  const updateFields = (
    addressObject: SuggestionType,
    postalCode: string,
    cityName: string
  ) => {
    if (addressObject?.postcode) {
      setValue(postalCode, addressObject.postcode);
    }

    if (addressObject?.locality) {
      setValue(cityName, addressObject.locality);
    }
  };

  const setShippingAddressObject = (
    address: string,
    addressObject: SuggestionType
  ) => {
    setValue('shipping_fullAddress', address);
    setShippingAddress(addressObject);

    updateFields(addressObject, 'shipping_postalCode', 'shipping_city');

    if (billingSameAsShipping) {
      setBillingAddress(addressObject);
    }
  };

  const setBillingAddressObject = (
    address: string,
    addressObject: SuggestionType
  ) => {
    setValue('billing_fullAddress', address);
    setBillingAddress(addressObject);

    updateFields(addressObject, 'billing_postalCode', 'billing_city');
  };

  const UpdateEntireForm = () => {
    //When fields are auto filled, react-hook-form does not update the form state
    //so we need to manually update the form state to reflect the current values in the form

    const form = formRef.current;
    if (form) {
      const formElements = form.elements;
      for (let i = 0; i < formElements.length; i++) {
        const element = formElements[i] as HTMLInputElement;
        if (element.name) {
          setValue(element.name as OrderKeys, element.value);

          if (billingSameAsShipping && element.name.startsWith('shipping_')) {
            setValue(
              element.name.replace('shipping_', 'billing_') as OrderKeys,
              element.value
            );
          }

          const value = getValues(element.name as OrderKeys);
          if (value) trigger(element.name as OrderKeys);
          element.value = value;
        }
      }
    }
  };

  const SetValue = () => {
    debounce(() => {
      UpdateEntireForm();
    }, 500);
  };

  const handleSetBillingSameAsShipping = (checked: boolean) => {
    //if billing is same as shipping, copy shipping values to billing fields
    //this is done to avoid validation errors when submitting the form
    //if billing is not same as shipping, clear billing fields
    //and clear any validation errors

    if (!checked) {
      setValue('billing_firstName', '');
      clearErrors('billing_firstName');
      setValue('billing_lastName', '');
      clearErrors('billing_lastName');
      setValue('billing_postalCode', '');
      clearErrors('billing_postalCode');
      setValue('billing_city', '');
      clearErrors('billing_city');
      setValue('billing_fullAddress', '');
      clearErrors('billing_fullAddress');
      setValue('billing_info', '');
      clearErrors('billing_info');

      if (!getValues('billing_fullAddress')) {
        setIsBillingAddressSelectedFromList(false);
      }
    } else {
      setValue('billing_firstName', getValues('shipping_firstName'));
      setValue('billing_lastName', getValues('shipping_lastName'));
      setValue('billing_postalCode', getValues('shipping_postalCode'));
      setValue('billing_city', getValues('shipping_city'));
      setValue('billing_fullAddress', getValues('shipping_fullAddress'));
    }

    setBillingSameAsShipping(!billingSameAsShipping);
  };

  // When switching shipping destination to another country a different address field is displayed.
  // We clear some fields to prevent the user from bypassing validation and submitting with an address to another country than the one currently selected.
  const resetBillingAndShippingAddressFields = () => {
    setValue('shipping_fullAddress', '');
    clearErrors('shipping_fullAddress');
    setValue('shipping_postalCode', '');
    clearErrors('shipping_postalCode');
    setValue('shipping_city', '');
    clearErrors('shipping_city');

    setValue('billing_fullAddress', '');
    clearErrors('billing_fullAddress');
    setValue('billing_postalCode', '');
    clearErrors('billing_postalCode');
    setValue('billing_city', '');
    clearErrors('billing_city');
  };

  const switchShippingDestinationHandler = async (
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    event.preventDefault();
    resetBillingAndShippingAddressFields();

    const countryCode = event.target.value;
    setSelectedShippingDestination(countryCode);
    const response = (await SwitchShippingDestination(
      countryCode
    )) as SwitchShippingDestinationResponseModel;

    if (!response) {
      return;
    }

    if (response.requiresCustomerConfirmation && response.confirmation) {
      setRequiresCustomerConfirmation(response.requiresCustomerConfirmation);
      setPopupModel(response.confirmation);
      setPopupIsVisable(true);
    }

    if (response.destinationSwitched) {
      await FetchCartAndNotifyAll(languageRoute);
      setSelectedShippingDestination(response.targetShippingDestination.code);
    }
  };

  const onPopupCancel = () => {
    setSelectedShippingDestination(currentShippingDestination.code);
    setPopupIsVisable(false);
  };

  const onPopupSubmit = async () => {
    await SetShippingDestination(selectedShippingDestination, false);
    await FetchCartAndNotifyAll(languageRoute);
    setPopupIsVisable(false);
    resetBillingAndShippingAddressFields();
  };

  const [popupIsVisable, setPopupIsVisable] = useState<boolean>(false);

  return (
    <Root ref={formRef} onSubmit={handleSubmit(submit)}>
      <InputGroup>
        <InputRow>
          <InputReactForm
            title={firstName}
            placeholder={firstName}
            name="shipping_firstName"
            errorText={
              formState ? formState.errors.shipping_firstName?.message : ''
            }
            error={!!formState?.errors.shipping_firstName}
            register={register}
            required
            maxLength={MAX_NAME_LENGTH}
            autoComplete="given-name"
            onChange={SetValue}
          />
          <InputReactForm
            title={lastName}
            placeholder={lastName}
            name="shipping_lastName"
            errorText={
              formState ? formState.errors.shipping_lastName?.message : ''
            }
            error={!!formState?.errors.shipping_lastName}
            register={register}
            required
            autoComplete="family-name"
            maxLength={MAX_NAME_LENGTH}
            onChange={SetValue}
          />
        </InputRow>

        <SelectListRow>
          <Label htmlFor="shipping_country">{country}</Label>
          <SelectList
            name="shipping_country"
            id="shipping_country"
            onChange={(event) => switchShippingDestinationHandler(event)}
            value={selectedShippingDestination}
          >
            {shippingDestinations.map((destination) => (
              <SelectListOption key={destination.code} value={destination.code}>
                {destination.name}
              </SelectListOption>
            ))}
          </SelectList>
        </SelectListRow>

        {requiresCustomerConfirmation && (
          <PromptPopup
            IsOpen={popupIsVisable}
            popupHeading={popupModel.heading}
            popupBodyText={popupModel.bodyText}
            CancelButtonText={popupModel.cancelButtonText}
            ActionButtonText={popupModel.actionButtonText}
            onCancelButtonClick={onPopupCancel}
            onActionButtonClick={onPopupSubmit}
          ></PromptPopup>
        )}

        {showForSpecificCountries ? (
          <>
            <AutoSuggestAddress
              name="shipping_fullAddress"
              control={control}
              countryCode={selectedShippingDestination.toLowerCase()}
              register={register}
              errorText={
                formState ? formState.errors.shipping_fullAddress?.message : ''
              }
              error={!!formState?.errors.shipping_fullAddress}
              onAddressChange={(
                address: string,
                addressObject: SuggestionType,
                isSelectedFromList: any
              ) => {
                setShippingAddressObject(address, addressObject);
                SetValue();
                setIsShippingAddressSelectedFromList(isSelectedFromList);
              }}
            />
            <InputReactForm
              title={infoLabel}
              name="shipping_info"
              placeholder={infoLabel}
              register={register}
              autoComplete="shipping info"
              onChange={SetValue}
            />
          </>
        ) : (
          <InputReactForm
            title={address}
            placeholder={address}
            name="shipping_fullAddress"
            errorText={
              formState ? formState.errors.shipping_fullAddress?.message : ''
            }
            error={!!formState?.errors.shipping_fullAddress}
            register={register}
            required
            maxLength={MAX_LENGTH_ADDRESS}
            autoComplete="shipping street-address"
            onChange={SetValue}
          />
        )}

        <InputRow>
          <InputReactForm
            name="shipping_postalCode"
            title={zipCode}
            placeholder={isDenmark ? '8888' : zipCode}
            errorText={
              formState ? formState.errors.shipping_postalCode?.message : ''
            }
            error={!!formState?.errors.shipping_postalCode}
            register={register}
            required
            autoComplete="shipping postal-code"
            onChange={SetValue}
            css={{
              '@bpMin481': {
                maxWidth: isDenmark ? '85px' : '135px',
                '& input': {
                  textAlign: 'center',
                },
              },
            }}
            disabled={isDenmark ? true : false}
          />
          <InputReactForm
            title={city}
            placeholder={city}
            name="shipping_city"
            errorText={formState ? formState.errors.shipping_city?.message : ''}
            error={!!formState?.errors.shipping_city}
            register={register}
            required
            maxLength={MAX_LENGTH_CITY}
            autoComplete="shipping address-level2"
            onChange={SetValue}
            disabled={isDenmark ? true : false}
          />
        </InputRow>

        <Checkbox
          text={billingAddressSameAsDeliveryAddress}
          isChecked={billingSameAsShipping}
          onCheck={(e) => handleSetBillingSameAsShipping(e.target.checked)}
        />
      </InputGroup>

      {!billingSameAsShipping && (
        <InputGroup>
          <Heading tag="h3" size="xs" weights="semiBold">
            {billingAddressLabel}
          </Heading>

          <InputRow>
            <InputReactForm
              title={firstName}
              placeholder={firstName}
              name="billing_firstName"
              errorText={
                formState ? formState.errors.billing_firstName?.message : ''
              }
              error={!!formState?.errors.billing_firstName}
              register={register}
              required
              maxLength={MAX_NAME_LENGTH}
              autoComplete="billing given-name"
              onChange={SetValue}
            />
            <InputReactForm
              title={lastName}
              name="billing_lastName"
              placeholder={lastName}
              errorText={
                formState ? formState.errors.billing_lastName?.message : ''
              }
              error={!!formState?.errors.billing_lastName}
              register={register}
              required
              maxLength={MAX_NAME_LENGTH}
              autoComplete="billing family-name"
              onChange={SetValue}
            />
          </InputRow>

          {showForSpecificCountries ? (
            <>
              <AutoSuggestAddress
                name="billing_fullAddress"
                countryCode={selectedShippingDestination.toLowerCase()}
                control={control}
                register={register}
                errorText={
                  formState ? formState.errors.billing_fullAddress?.message : ''
                }
                error={!!formState?.errors.billing_fullAddress}
                onAddressChange={(
                  address: string,
                  addressObject: SuggestionType,
                  isSelectedFromList: any
                ) => {
                  setBillingAddressObject(address, addressObject);
                  SetValue();
                  setIsBillingAddressSelectedFromList(isSelectedFromList);
                }}
              />
              <InputReactForm
                title={infoLabel}
                name="billing_info"
                placeholder={infoLabel}
                register={register}
                autoComplete="billing info"
                onChange={SetValue}
              />
            </>
          ) : (
            <InputReactForm
              title={address}
              placeholder={address}
              name="billing_fullAddress"
              errorText={
                formState ? formState.errors.billing_fullAddress?.message : ''
              }
              error={!!formState?.errors.billing_fullAddress}
              register={register}
              required
              maxLength={MAX_LENGTH_ADDRESS}
              autoComplete="billing street-address"
              onChange={SetValue}
            />
          )}

          <InputRow>
            <InputReactForm
              title={zipCode}
              placeholder={zipCode}
              name="billing_postalCode"
              errorText={formState.errors.billing_postalCode?.message}
              error={!!formState?.errors.billing_postalCode}
              register={register}
              required
              autoComplete="billing postal-code"
              onChange={SetValue}
              css={{
                '@bpMin481': {
                  maxWidth: isDenmark ? '85px' : '135px',
                  '& input': {
                    textAlign: 'center',
                  },
                },
              }}
              disabled={isDenmark ? true : false}
            />
            <InputReactForm
              title={city}
              placeholder={city}
              name="billing_city"
              errorText={formState.errors.billing_city?.message}
              error={!!formState?.errors.billing_city}
              register={register}
              maxLength={MAX_LENGTH_CITY}
              required
              autoComplete="billing address-level2"
              onChange={SetValue}
              disabled={isDenmark ? true : false}
            />
          </InputRow>
        </InputGroup>
      )}
      {active && (
        <Button<typeof PrimaryButton>
          element="PrimaryButton"
          props={{
            text: goToTermsAndConditions,
            color: 'Regular',
            hug: 'width',
          }}
          onClick={submit}
        />
      )}
    </Root>
  );
};

const Root = styled('form', {
  marginTop: '$s100',
  button: {
    marginTop: '$s200',
  },
});

const InputGroup = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$s100',
  '& div': {
    marginBottom: 0,
  },
  '&:nth-of-type(2)': {
    marginTop: '$s100',
  },
});

const InputRow = styled('div', {
  display: 'flex',
  gap: '$s75',
  flexDirection: 'column',
  '@bpMin481': {
    flexDirection: 'row',
  },
  '& div': {
    marginBottom: 0,
    flex: 1,
  },
});

const SelectListRow = styled('div', {
  '& div': {
    marginBottom: 0,
    flex: 1,
  },
});

export default DeliveryStep;
